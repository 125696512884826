import React, {Component} from 'react'
import Globe from './globe'
import {CardColumns, Container} from 'reactstrap'
import {faGlobeEurope, faInfoCircle, faLayerGroup} from '@fortawesome/free-solid-svg-icons'

import LayersCard from './bootstrap/LayersCard'
import NavBar from './bootstrap/NavBar'
import NavBarItem from './bootstrap/NavBarItem'
import InformationCard from "./bootstrap/InformationCard";
import SearchBox from "./bootstrap/SearchBox";

import './App.css'
import config, {layers} from "./config/config";
import NavBarLink from "./bootstrap/NavBarLink";

export default class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      latitude: config.view.latitude,
      longitude: config.view.longitude,
      altitude: config.view.altitude,
      globe: null
    };

    this.globeRef = React.createRef();

    this.navBarRef = React.createRef();
    this.baseRef = React.createRef();
    this.dataRef = React.createRef();
    this.informationRef = React.createRef();
  }

  componentDidMount() {
    // Get the component with the WorldWindow after mounting
    this.setState({globe: this.globeRef.current})
  }

  render() {
    const globe = this.globeRef.current;

    const closingCards = [this.navBarRef.current, this.baseRef.current, this.dataRef.current, this.informationRef.current];

    const navbarItems = [
      <NavBarItem key='base' title='Basemap' icon={faGlobeEurope} card={this.baseRef.current} closingCards={closingCards}/>,
      <NavBarItem key='layer' title='Layers' icon={faLayerGroup} card={this.dataRef.current} closingCards={closingCards}/>,
      <NavBarItem key='information' title='Information' icon={faInfoCircle} card={this.informationRef.current} closingCards={closingCards}/>,
    ];

    navbarItems.push(config.links.map(link => <NavBarLink title={link.title} icon={link.icon} href={link.href}/>));

    const navbarSearch = config.features.search && <SearchBox globe={globe} mapQuestApiKey="9lveC1YtIIomMPiqyEHAIWwjyx8xrdAL"/>;

    return (
      <div>
        <NavBar
            ref={this.navBarRef}
            logo='./logo.png'
            title=''
            items={navbarItems}
            search={navbarSearch}/>

        <Container fluid className='p-0'>
          <div className='globe'>
              <Globe
                ref={this.globeRef}
                layers={layers}
                latitude={this.state.latitude}
                longitude={this.state.longitude}
                altitude={this.state.altitude}
                maxAltitude={config.limits.maxAltitude}
                sector={config.limits.sector}/>
          </div>
          <div className='overlayCards noninteractive'>
            <CardColumns>
              <LayersCard
                ref={this.baseRef}
                title='Base'
                icon={'globeEurope'}
                categories={['base','overlay']}
                globe={globe} />
              <LayersCard
                ref={this.dataRef}
                title='Data'
                icon={'layerGroup'}
                categories={['data', 'atmosphere']}
                globe={globe} />
              <InformationCard
                  ref={this.informationRef}
                  title={'Information'}
                  icon={'infoCircle'}
                  globe={globe}/>
            </CardColumns>
          </div>
        </Container>
      </div>
    )
  }
}

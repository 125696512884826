import {EoxLabels, EoxSentinel2Cloudless2018, EoxStreets} from "../layers";
import {
    EcmwfPM10,
    EcmwfPM25,
    EcmwfSurfaceCH4,
    EcmwfSurfaceCO2,
    EcmwfSurfaceNO2,
    EcmwfSurfaceO3,
    EcmwfSurfaceSO2
} from "../layers/ecmwf";
import CopernicusCorineLandCover2018 from "../layers/copernicus/CopernicusCorineLandCover2018";
import {faBullseye} from "@fortawesome/free-solid-svg-icons";
import {
    TampereNoisePolution,
    TampereCityCenterParking,
    TampereCombinedDetailedPlanMap,
    TampereSolarEnergyPotentialOfBuildings,
    TampereGeoTiff
} from "../layers/tampere";

/* global WorldWind */

const config = {
    features: {
        search: false,
    },
    view: {
        latitude: 61.5,
        longitude: 23.766667,
        altitude: 5e4,
        projection: "MERCATOR",
    },
    limits: {
        sector: new WorldWind.Sector(60.619, 61.957, 22.631, 24.997),
        maxAltitude: 1e5,
    },
    links: [
        {
            icon: faBullseye,
            title: 'Ground sensors',
            href: 'http://51.15.123.125:3838/airqualitysensor/',
        },
    ]
};

export default config;

export const layers = [
    /**
     * Basemap layers
     */
    {layer: new EoxSentinel2Cloudless2018(), options: {category: 'base', enabled: true}},

    /**
     * Overlay layers
     */
    {layer: new EoxLabels(), options: {category: 'overlay', enabled: false, opacity: 0.8}},
    {layer: new EoxStreets(), options: {category: 'overlay', enabled: false, opacity: 0.8}},

    /**
     * Configuration layers
     */
    {layer: 'coordinates', options: {category: 'setting', enabled: true}},

    /**
     * Atmosphere layers
     */
    {layer: new EcmwfPM10(), options: {category: 'data', enabled: false, opacity: 0.8}},
    {layer: new EcmwfPM25(), options: {category: 'data', enabled: false, opacity: 0.8}},
    {layer: new EcmwfSurfaceCH4(), options: {category: 'data', enabled: false, opacity: 0.8}},
    {layer: new EcmwfSurfaceCO2(), options: {category: 'data', enabled: false, opacity: 0.8}},
    {layer: new EcmwfSurfaceNO2(), options: {category: 'data', enabled: false, opacity: 0.8}},
    {layer: new EcmwfSurfaceO3(), options: {category: 'data', enabled: false, opacity: 0.8}},
    {layer: new EcmwfSurfaceSO2(), options: {category: 'data', enabled: false, opacity: 0.8}},

    {layer: new CopernicusCorineLandCover2018(), options: {category: 'data', enabled: false, opacity: 0.8}},

    /**
     * Tampere data layers
     */
    {layer: new TampereSolarEnergyPotentialOfBuildings(), options: {category: 'data', enabled: false, opacity: 0.6}},
    {layer: new TampereCombinedDetailedPlanMap(), options: {category: 'data', enabled: false, opacity: 0.6}},
    {layer: new TampereCityCenterParking(), options: {category: 'data', enabled: false, opacity: 0.6}},
    {layer: new TampereNoisePolution(), options: {category: 'data', enabled: false, opacity: 1.0}},
    {layer: new TampereGeoTiff(), options: {category: 'data', enabled: false, opacity: 1.0}},
];
